import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import Banner from "../component/section/banner";
import Offers from "../component/section/Offers";
import GetMOre from "../component/section/GetMore";
import WhyC2C from "../component/section/WhyC2C";
import AllCourse from "../component/section/AllCourses";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <Banner />
      <Offers />
      <WhyC2C />
      <GetMOre />
      <AllCourse />
      <Footer />
    </Fragment>
  );
};

export default Home;
