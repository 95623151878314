import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

const subTitle =
  "College 2 Company is a comprehensive web-portal for NEP 2020 Scheme Syllabus focused study materials. Currently the portal is offering content for Undergraduate courses and covering the Syllabi of 14 major universities of Karnataka. The universities covered are listed below.";
const title = "What The Portal Offers ?";

const sponsorList = [
  {
    imgUrl: "assets/images/offers/icon/01.png",
  },
  {
    imgUrl: "assets/images/offers/icon/02.png",
  },
  {
    imgUrl: "assets/images/offers/icon/03.png",
  },
  {
    imgUrl: "assets/images/offers/icon/04.png",
  },
  {
    imgUrl: "assets/images/offers/icon/05.png",
  },
  {
    imgUrl: "assets/images/offers/icon/06.png",
  },
  {
    imgUrl: "assets/images/offers/icon/07.png",
  },
  {
    imgUrl: "assets/images/offers/icon/08.png",
  },
  {
    imgUrl: "assets/images/offers/icon/09.png",
  },
  {
    imgUrl: "assets/images/offers/icon/10.png",
  },
  {
    imgUrl: "assets/images/offers/icon/11.png",
  },
  {
    imgUrl: "assets/images/offers/icon/12.png",
  },
  {
    imgUrl: "assets/images/offers/icon/13.png",
  },
  {
    imgUrl: "assets/images/offers/icon/14.png",
  },
];

const Offers = () => {
  return (
    <div className="category-section padding-tb">
      <Container>
        <div className="section-header text-center">
          <h2 className="title">{title}</h2>
          <span className="text">{subTitle}</span>
        </div>

        <div className="sponsor-section section-bg">
          <Container>
            <div className="section-wrapper">
              <div className="sponsor-slider">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={2}
                  loop={"true"}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    0: {
                      width: 0,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    },
                    1200: {
                      width: 1200,
                      slidesPerView: 5.5,
                    },
                  }}
                >
                  {sponsorList.map((val, i) => (
                    <SwiperSlide key={i}>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <div>
                            <img
                              src={`${val.imgUrl}`}
                              alt={`${val.imgAlt}`}
                              style={{ height: "23vh" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default Offers;
