import { Fragment, useState } from "react";
import Header from "../layout/header";
import PageHeader from "../layout/pageheader";
import GroupSelect from "../sidebar/group-select";
import Pagination from "../sidebar/pagination";
import SkillSelect from "../sidebar/skill-select";
import Footer from "../layout/footer";
import { BscData } from "./CourseData";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const Courses = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentVideoLinks, setCurrentVideoLinks] = useState([]);

  const launchFullscreen = (videoLinks) => {
    setCurrentVideoLinks(videoLinks);
    setCurrentVideoIndex(0);
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  const showNextVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex + 1) % currentVideoLinks.length
    );
  };

  const showPrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? currentVideoLinks.length - 1 : prevIndex - 1
    );
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Archives: Courses"} curPage={"Course Page"} />
      <GroupSelect />
      <div className="course-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="course-showing-part-left">
                  <p>Showing 1-6 of 10 results</p>
                </div>
                <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                  <span>Sort by :</span>
                  <div className="select-item">
                    <SkillSelect select={"all"} />
                    <div className="select-icon" style={{ color: "#0a2351" }}>
                      <i className="icofont-rounded-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* courses  */}
            <div className="course-section  section-bg">
              <div className="container">
                <div className="section-wrapper">
                  <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                    {BscData.map((val, i) => (
                      <div className="col" key={i}>
                        <div className="course-item">
                          <div
                            className="course-inner"
                            style={{ height: "60vh" }}
                          >
                            <div className="course-thumb">
                              <img
                                src={`${val.imgUrl}`}
                                alt={`${val.imgAlt}`}
                                style={{ height: "30vh", cursor: "pointer" }}
                                onClick={() => launchFullscreen(val.videoLinks)}
                              />
                            </div>
                            <div className="course-content">
                              <Link to="/">
                                <h4>{val.title}</h4>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {isFullscreen && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    zIndex: 10000,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <button
                    onClick={closeFullscreen}
                    style={{
                      position: "absolute",
                      top: "8%",
                      right: "10px",
                      background: "transparent",
                      border: "none",
                      color: "white",
                      fontSize: "50px",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </button>
                  {currentVideoLinks.length > 1 && currentVideoIndex > 0 && (
                    <button
                      onClick={showPrevVideo}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        background: "transparent",
                        border: "none",
                        color: "white",
                        fontSize: "50px",
                        cursor: "pointer",
                      }}
                    >
                      &#8249;
                    </button>
                  )}
                  <ReactPlayer
                    url={currentVideoLinks[currentVideoIndex]}
                    playing
                    controls
                    width="100%"
                    height="100%"
                    style={{
                      backgroundColor: "black",
                    }}
                  />
                  {currentVideoLinks.length > 1 &&
                    currentVideoIndex < currentVideoLinks.length - 1 && (
                      <button
                        onClick={showNextVideo}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          background: "transparent",
                          border: "none",
                          color: "white",
                          fontSize: "50px",
                          cursor: "pointer",
                        }}
                      >
                        &#8250;
                      </button>
                    )}
                </div>
              )}
            </div>

            <Pagination />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Courses;
