import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const subTitle = "About Our  College 2 Company ";
const title =
  "Welcome to College 2 Company Enhance your skills with our Company";
const desc =
  "We are an education portal dedicated to providing accessible, high-quality learning opportunities for students across India. Our mission is to democratize education by breaking down barriers to learning and making top notch education accessible to everyone. Our platform offers online courses, tutorials, and resources that cater to various educational needs and interests. From College syllabus to working as a professional in multinational companies, we cater to a diverse audience and provide them with the tools and resources they need to succeed. With a team of experienced educators and industry professionals, we strive to create engaging and interactive content that provides a rich learning experience. Our courses are designed to be flexible and self-paced, allowing learners to study at their own convenience and from anywhere in the world. We believe that education is the key to unlocking one's full potential, and our goal is to provide a platform where everyone has the opportunity to grow and succeed.";

const year = "30+";
const expareance = "Years Of Experiences";

const AboutPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"About Our College 2 Company"} curPage={"About"} />
      <div className="about-section style-3 padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
            <div className="col">
              <div className="about-left">
                <div className="about-thumb">
                  <img src="assets/images/about/01.jpg" alt="about" />
                </div>
                <div className="abs-thumb">
                  <img src="assets/images/about/02.jpg" alt="about" />
                </div>
                <div
                  className="about-left-content"
                  style={{ background: "#0a2351" }}
                >
                  <h3>{year}</h3>
                  <p>{expareance}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="about-right">
                <div className="section-header">
                  <span className="subtitle" style={{ color: "#0a2351" }}>
                    {subTitle}
                  </span>
                  <h2 className="title">{title}</h2>
                  <p>{desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AboutPage;
