export const BscData = [
  {
    imgUrl: "assets/images/offers/course/01.png",
    ProfImgUrl: "assets/images/featCourse/prof1.jpeg",
    title: "Data Structures using C with LAB",
    desc: "Prof.Bharathi",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=WiFeJN8iRak"],
  },
  {
    imgUrl: "assets/images/offers/course/02.png",
    ProfImgUrl: "assets/images/featCourse/prof2.jpeg",
    title: "OOP Concepts and Programming in JAVA with LAB",
    desc: "Prof. Chandan Hegde and Prof Basavaraju",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=5eEGqNkiV7s"],
  },
  {
    imgUrl: "assets/images/offers/course/03.jpg",
    ProfImgUrl: "assets/images/featCourse/prof3.png",
    title: "Computer Architecture",
    desc: "Prof. Rashmi Eswar",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=lI_NJORvcwA"],
  },
  {
    imgUrl: "assets/images/offers/course/04.jpeg",
    ProfImgUrl: "assets/images/featCourse/prof4.png",
    title: "Data Base Management Systems with LAB",
    desc: "Prof. Aruna devi",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=LlPUhicQZWY"],
  },
  {
    imgUrl: "assets/images/offers/course/05.jpg",
    ProfImgUrl: "assets/images/featCourse/prof5.png",
    title: "Python Programming with LAB",
    desc: "Prof. Nirmala Guptha ",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=Tc5OSt3OFoo"],
  },
  {
    imgUrl: "assets/images/offers/course/06.jpg",
    ProfImgUrl: "assets/images/featCourse/prof5.png",
    title: "The Design And Analysis Of Algorithms",
    desc: "Prof. Kanagavalli",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=GEHlyQQy2UQ"],
  },
  {
    imgUrl: "assets/images/offers/course/07.jpg",
    ProfImgUrl: "assets/images/featCourse/prof7.png",
    title: "Cryptography and Network Security",
    desc: "Prof. Sajana Balan",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=F5KHht-fNU0"],
  },
  {
    imgUrl: "assets/images/offers/course/08.jpg",
    ProfImgUrl: "assets/images/featCourse/prof8.png",
    title: "Operations Research",
    desc: "Prof. kumudavalli",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=n-pBecH4NpM"],
  },
  {
    imgUrl: "assets/images/offers/course/09.jpg",
    ProfImgUrl: "assets/images/featCourse/prof9.png",
    title: "Theory of Computation",
    desc: "Prof. Ashvini Diwakar",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=sO83Bn1phSE"],
  },
  {
    imgUrl: "assets/images/offers/course/10.jpg",
    ProfImgUrl: "assets/images/featCourse/prof10.png",
    title: "System  Programming",
    desc: "Prof. Jayashree Nair",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=GOnrtGfmFn8"],
  },
  {
    imgUrl: "assets/images/offers/course/11.jpg",
    ProfImgUrl: "assets/images/featCourse/prof11.png",
    title: "Fundamentals of Computers",
    desc: "Prof. Sweta",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=phuezmBdyqU"],
  },
  {
    imgUrl: "assets/images/offers/course/12.jpg",
    ProfImgUrl: "assets/images/featCourse/prof12.png",
    title: "Computer Fundamentals",
    desc: "Prof. Preeti",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=h66KYhF8n0Y"],
  },
];

export const LabWorksData = [
  {
    imgUrl: "assets/images/featCourse/Lab/01.jpg",
    ProfImgUrl: "assets/images/featCourse/prof3.png",
    title: "Computer Architecture",
    desc: "Prof. Rashmi Eswar",
    siteLink: "#",
    videoLinks: ["https://https://youtu.be/YxJNuhtnsk4"],
  },
  {
    imgUrl: "assets/images/featCourse/Lab/02.jpg",
    ProfImgUrl: "assets/images/featCourse/prof7.png",
    title: "Cryptography and Network Security",
    desc: "Prof. Sajana Balan",
    siteLink: "#",
    videoLinks: ["https://youtu.be/FwhF1rHfjhc"],
  },
  {
    imgUrl: "assets/images/featCourse/04.jpeg",
    ProfImgUrl: "assets/images/featCourse/prof4.png",
    title: "Data Base Management Systems with LAB",
    desc: "Prof. Aruna devi",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=LlPUhicQZWY"],
  },
  {
    imgUrl: "assets/images/featCourse/Lab/04.png",
    ProfImgUrl: "assets/images/featCourse/prof1.jpeg",
    title: "Data Structures - Linked List",
    desc: "Prof.Bharathi Ramesh",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=Jlx9Yvb8PkU"],
  },
  {
    imgUrl: "assets/images/featCourse/Lab/05.png",
    ProfImgUrl: "assets/images/featCourse/prof1.jpeg",
    title: "Data Structures - Postfix",
    desc: "Prof.Bharathi Ramesh",
    siteLink: "#",
    videoLinks: ["https://youtu.be/AH2xxFvKCTs"],
  },

  {
    imgUrl: "assets/images/featCourse/02.png",
    ProfImgUrl: "assets/images/featCourse/prof2.jpeg",
    title: "OOP Concepts and Programming in JAVA ",
    desc: "Prof. Chandan Hegde and Prof Basavaraju",
    siteLink: "#",
    videoLinks: ["https://youtu.be/2ljv8XtI_ZM"],
  },
  {
    imgUrl: "assets/images/featCourse/Lab/07.png",
    ProfImgUrl: "assets/images/featCourse/prof8.png",
    title: "Operation Research",
    desc: "Prof. kumudavalli",
    siteLink: "#",
    videoLinks: ["https://youtu.be/-Hl5GWq88dY"],
  },
  {
    imgUrl: "assets/images/featCourse/09.jpg",
    ProfImgUrl: "assets/images/featCourse/prof9.png",
    title: "Theory of Computation",
    desc: "Prof. Ashvini Diwakar",
    siteLink: "#",
    videoLinks: ["https://www.youtube.com/watch?v=sO83Bn1phSE"],
  },
];

export const BcomData = [
  {
    imgUrl: "assets/images/featCourse/bcom/01.jpg",
    ProfImgUrl: "assets/images/featCourse/prof5.png",
    title: "Advanced Financial Accounting",
    desc: "Prof.Vinay",
    videoLinks: ["https://www.youtube.com/watch?v=hh6awOrTSfM"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/02.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof2.jpeg",
    title: "Business Ethics",
    desc: "Prof. Poornima K",
    videoLinks: ["https://www.youtube.com/watch?v=Pl45LBWuvNY"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/03.jpg",
    ProfImgUrl: "assets/images/featCourse/prof5.png",
    title: "Cost Accounting",
    desc: "Prof. Rohini",
    videoLinks: ["https://www.youtube.com/watch?v=9To1kgbaiIg"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/04.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof4.jpeg",
    title: "Business Regulatory Framework",
    desc: "Prof. Sujay C",
    videoLinks: ["https://www.youtube.com/watch?v=FFQKY2Wm7uA"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/05.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof5.jpeg",
    title: "Financial Accountancy",
    desc: "Prof. Yashaswini ",
    videoLinks: ["https://www.youtube.com/watch?v=FMNDBvYsBNc"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/06.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof6.jpeg",
    title: "The Design And Analysis Of Algorithms",
    desc: "Prof. Raghavendra",
    videoLinks: ["https://www.youtube.com/watch?v=-1fwXyPEh98"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/07.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof7.jpeg",
    title: " Business Management & Startups (DSC)",
    desc: "Prof. Harshini",
    videoLinks: ["https://www.youtube.com/watch?v=hERDCBnMOdk"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/08.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof2.jpeg",
    title: "Principles of Marketing (DSC)",
    desc: "Prof. Poornima K",
    videoLinks: ["https://www.youtube.com/watch?v=2fdCMVwsgiY"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/09.jpeg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof5.jpeg",
    title: "Corporate Accounting (DSC)",
    desc: "Prof. Yashaswini ",
    videoLinks: ["https://www.youtube.com/watch?v=6dvGk9D_s1o"],
  },
  {
    imgUrl: "assets/images/featCourse/bcom/10.jpg",
    ProfImgUrl: "assets/images/featCourse/bcom/prof2.jpeg",
    title: "Entrepreuneurship Skills",
    desc: "Prof. Poornima K",
    videoLinks: ["https://www.youtube.com/watch?v=9vcu558U6-g"],
  },
];
