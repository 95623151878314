import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ChevronUp } from "react-bootstrap-icons";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down 100px
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top position to 0 when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <Container fluid>
      {isVisible && (
        <div
          className="scroll-to-top"
          style={{
            position: "fixed",
            bottom: "160px",
            right: "20px",
            zIndex: 1000,
            cursor: "pointer",
          }}
          onClick={scrollToTop}
        >
          <ChevronUp
            style={{
              //   backgroundColor: "#0a2351",
              backgroundColor: "white",
              color: "#0a2351",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              padding: "12px",
              fontSize: "24px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default ScrollToTop;
