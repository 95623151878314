import { BrowserRouter, Routes, Route } from "react-router-dom";
import "swiper/css";

import ErrorPage from "./page/404";
import AboutPage from "./page/about";

import ContactPage from "./page/contact";
import CoursePage from "./page/course";

import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import SignupPage from "./page/signup";
import Courses from "./component/section/Courses";
import WhatsAppIcon from "./component/section/Whatsapp";
import ScrollToTop from "./component/section/ScrollToTop";
import CallIcon from "./component/section/Call";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="course" element={<CoursePage />} />
        <Route path="courses" element={<Courses />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="search-page" element={<SearchPage />} />
        <Route path="search-none" element={<SearchNone />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="forgetpass" element={<ForgetPass />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>

      <WhatsAppIcon />
      <CallIcon />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
