const GoogleMap = () => {
  return (
    <div className="map-area">
      <div className="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.722533066404!2d77.51416557404978!3d12.925546615888367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e56233a1a39%3A0x27a8e9e7cbc88de!2s98c%2C%2012th%20Cross%20Rd%2C%20Remco%20Bhel%20Layout%2C%20Ideal%20Homes%20Twp%2C%20Rajarajeshwari%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560098%2C%20India!5e0!3m2!1sen!2sbd!4v1718264382644!5m2!1sen!2sbd"></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
