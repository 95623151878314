import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { useState } from "react";
import { BscData } from "./CourseData";

const subTitle = "Explore Featured Courses";
const title = "Pick A Course To Get Started";

const AllCourses = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentVideoLinks, setCurrentVideoLinks] = useState([]);

  const launchFullscreen = (videoLinks) => {
    setCurrentVideoLinks(videoLinks);
    setCurrentVideoIndex(0);
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  const showNextVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex + 1) % currentVideoLinks.length
    );
  };

  const showPrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? currentVideoLinks.length - 1 : prevIndex - 1
    );
  };
  return (
    <div className="course-section padding-tb section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle" style={{ color: "#0a2351" }}>
            {subTitle}
          </span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
            {BscData.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item">
                  <div className="course-inner" style={{ height: "60vh" }}>
                    <div className="course-thumb">
                      <img
                        src={`${val.imgUrl}`}
                        alt={`${val.imgAlt}`}
                        style={{ height: "30vh", cursor: "pointer" }}
                        onClick={() => launchFullscreen(val.videoLinks)}
                      />
                    </div>
                    <div className="course-content">
                      <Link to="/">
                        <h4>{val.title}</h4>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isFullscreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <button
            onClick={closeFullscreen}
            style={{
              position: "absolute",
              top: "8%",
              right: "10px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "50px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          {currentVideoLinks.length > 1 && currentVideoIndex > 0 && (
            <button
              onClick={showPrevVideo}
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                background: "transparent",
                border: "none",
                color: "white",
                fontSize: "50px",
                cursor: "pointer",
              }}
            >
              &#8249;
            </button>
          )}
          <ReactPlayer
            url={currentVideoLinks[currentVideoIndex]}
            controls
            playing
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  rel: 0,
                  iv_load_policy: 3,
                  controls: 1,
                  disablekb: 1,
                  showinfo: 0,
                  autoplay: 1,
                },
              },
            }}
            width="100%"
            height="100%"
          />
          {currentVideoLinks.length > 1 &&
            currentVideoIndex < currentVideoLinks.length - 1 && (
              <button
                onClick={showNextVideo}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "50px",
                  cursor: "pointer",
                }}
              >
                &#8250;
              </button>
            )}
        </div>
      )}
    </div>
  );
};

export default AllCourses;
