import React from "react";
import { Container } from "react-bootstrap";
import { Telephone } from "react-bootstrap-icons";

const CallIcon = () => {
  const handleClick = () => {
    window.open("tel: 8660103191", "_blank");
  };

  return (
    <Container fluid>
      <div
        className="d-flex justify-content-end align-items-end position-fixed"
        style={{
          bottom: "90px",
          right: "20px",
          zIndex: 1000,
        }}
      >
        <div
          onClick={handleClick}
          style={{
            // backgroundColor: "#1f1f33",
            backgroundColor: "#1A064F",
            color: "white",
            borderRadius: "50%",
            padding: "15px",
            fontSize: "24px",
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Telephone />
        </div>
      </div>
    </Container>
  );
};

export default CallIcon;
