import React from "react";

const title = "Why College 2 Company?";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Learn As per Syllabus",
    desc: "NEP 2020 - Syllabus Focussed",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Learn anywhere",
    desc: "Web portal & Mobile App",
  },
  {
    imgUrl: "assets/images/skill/icon/04.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Learn with experts",
    desc: "Highly Qualified & Experienced Faculties",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Salient Features",
    desc: "At just Rs. 5/ day, explore what all you can get",
  },
];

const WhyC2C = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
          <div className="col">
            <div className="about-right ">
              <div className="section-header">
                <h2 className="title">{title}</h2>
                {/* <p>{desc}</p> */}
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul">
                  {aboutList.map((val, i) => (
                    <li key={i}>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/01.png" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyC2C;
